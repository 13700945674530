export const serverConst = {
    END_POINT_IPPICA: 'https://betreaderweb.gcsoft.it/php/ippica',
    END_POINT_GET_SPECIALITA: '/get_specialita.php', 
    END_POINT_GET_EVENTI:'/get_eventi_marcatori.php',
    END_POINT_GET_EVENTO: '/get_evento_marcatori.php',
    END_POINT_GET_CONCESSIONARI:'/get_concessionari.php',
    END_POINT_GET_QUOTE_FOREVENTO:'/get_quote_for_evento.php',
    END_POINT_GET_QUOTA_DESCRIZIONE: '/get_quota_descrizione.php',
}

export const IppicaConst = {
    // versione del pannello
    VERSIONE: "2.5",

     // filtro data
     EVENTI_OGGI: 0,
     EVENTI_OGGI_DOMANI: 1,
     EVENTI_TUTTI: 2,
 
     // i valori associati ai singoli alert
     VALUE_ALERT_BF: 1,
     VALUE_ALERT_BF_PUNTA: 2,
 
     // disposizione dei marcatori 
     VISUALIZZAZIONE_1_COLONNA: 0,
     VISUALIZZAZIONE_2_COLONNE: 1,
     VISUALIZZAZIONE_3_COLONNE: 2,

     TIPO_ORDINAMENTO_PER_DATA: 0,
     TIPO_ORDINAMENTO_PER_CAMPIONATO:1,
 
     CONCESSIONARIO_VIRTUALE: 'MAX Odds',
 
 
     CONCESSIONARIO_BETFAIR: "BETFAIR",        // codice concessionario utilizzato per Betfair
 
     CONFRONTO: ["BETFAIR"],          // elenco dei concessionari che non verrano utilizzati
                                     // nel calcolo della quota massima, quota media e in generale
                                     // contiene solo i concessionari che servono come confronto
 
 
     ALTEZZA_RIGA_EVENTO: 59.5,
     ALTEZZA_RIGA_COMPETITOR_1_COLONNA_CHIUSA: 31,
     ALTEZZA_RIGA_COMPETITOR_2_3_COLONNE_CHIUSA: 50.5,
     ALTEZZA_NOME_COMPETITOR: 31,
     ALTEZZA_INTESTAZIONE_SPECIALITA: 46,

     KEY_STATE: "IPPICA_STATE",

     CONCESSIONARIO_ORDINAMENTO_FAVORITO: "MATCHPOINT",
     SPECIALITA_ORDINAMENTO_FAVORITO: 289, // VINCENTE

        // elenco delle specialità, suddivise per nazione, che devono vengono visualizzate in grigio e non hanno alert
     SPECIALITA_DISATTIVATE_CAMPI: {
/*		 
        'GB': {
            289: false,
        },
        'IE': {
            289: false,
        }
*/		
     },

     LARGHEZZA_SPECIALITA: 100,

     PROCESSA_IMPOSTA_QUOTE: 800, // tempo di attesa per processare il buffer di impostaQuotaValore tramite processaDati
}