import { checkEventoAlert, checkQuotaAlertBF } from "../../utils/ippica/alert";
import { IppicaActionsType } from "./constants"
import { IppicaConst } from '../../constants/ippica'
import { getMaxOdds, getConcessionariNonVisualizzati, cleanQuote, filterCheckEvento, getLocalState, saveLocalState, inizializzaEventoCampi, aggiornaListaCampionati, campionato_generaelenco, ordinaCompetitorPerFavorito, setSpecialitaAttivePerCampionato, verificaQuotePresentiEvento, valuateAlerts } from "../../utils/ippica/odds_function"
import alert_sound from '../../assets/sounds/avviso.mp3'

const local_state = getLocalState()

const INIT_STATE = {
  concessionari: [],
  eventi: [],
  quote: {},
  specialita: [],
  token: '',
  campionati: [],
  distance_banca: local_state.impostazioni.distanza_banca,
  distance_punta: local_state.impostazioni.distanza_punta,
  filtro_evento: '',
  filtro_data: IppicaConst.EVENTI_TUTTI,
  filtro_competitor: '',
  alert_attivi: local_state.impostazioni.alert_abilitati,
  alert_eye: false,
  loading: true,
  alert_sound_enabled: true,
  ref_list: null,
  ultimo_aggiornamento: null,
  visualizza_quote_presenti: false, // visualizza solo gli eventi con quote presenti quando è False, Quando è True li visualizziamo tutti
};

type IppicaAction = {
  type: string,
  payload: { actionType?: string, data?: any, error?: string },
};

type State = { 
  concessionari?: [] | null,
  eventi?: [] | null,
  quote?: {} | null,
  specialita?: [] | null,
  token?: '' | null,
  campionati?: [] | null,
  distance_banca?: 0 | null,
  distance_punta?: 0 | null,
  loading?: Boolean | null,
  alert_sound_enabled?: Boolean | null,
  filtro_evento?: '' | null,
  filtro_data?: Object | null,
  filtro_competitor?: '' | null,
  alert_attivi?: Object | null,
  alert_eye?: Boolean | null,
  ref_list?: Object | null,
  ultimo_aggiornamento?: Object | null,
  visualizza_quote_presenti?: Boolean | null,
};

var countLoadedEvent = 0

const suono_alert = new Audio(alert_sound)

const Ippica = (state: State = INIT_STATE, action: IppicaAction): any => {
  // definizioni delle variabili usate all'interno dello switch [Sulla destra tutti i case dove sono usate]
  
  var tempConcessionari; // ADD_CONCESSIONARI, CHANGE_VISUALIZATION_CONCESSIONARIO
  var index; // CHANGE_VISUALIZATION_CONCESSIONARIO, CHANGE_VISUALIZATION_SPECIALITA, CHANGE_VISUALIZATION_EVENTI
  var eventi_scaricati; // ADD_EVENTI, ADD_EVENTO
  var tempEvento; // ADD_EVENTI, ADD_EVENTO
  var evento; // ADD_EVENTO
  var tempQuote; 
  var quote; // SET_QUOTA_EVENTO
  var evento_id;
  var tempLoading; // SET_QUOTA_EVENTO
  var quota_id;
  var concessionario_codice; // SET_QUOTA
  var concessionari_da_non_contare;
  var tempSpecialita; // ADD_SPECIALITA, CHANGE_VISUALIZATION_SPECIALITA
  var tempCampionati; // SET_CAMPIONATI, CHANGE_VISUALIZATION_EVENTI
  var campionatiInseriti; // SET_CAMPIONATI
  var old_alert; // SET_QUOTA
  var campionato; // SET_CAMPIONATI, CHANGE_VISUALIZATION_EVENTI
  var tempEventi; // CHANGE_VISUALIZATION_EVENTI
  var eventi_da_non_visualizzare; 
  var alert_abilitati; 
  var index_competitor; 
  var competitor
  var concessionari_da_non_considerare; // CHECK_ALERT_AVG_PERCENTUALE, CHECK_EVENT_ALERT_AVG_PERCENTUALE
  var currentTimestamp;
  var evento_rimosso;
  var start_date;
  var end_date;

  switch (action.type) {
    case IppicaActionsType.ADD_CONCESSIONARI:
      tempConcessionari = []
      action.payload.forEach(concessionario => {
        tempConcessionari.push({...concessionario, visible: true})
      });
      // aggiungo un concessionario virtuale
      tempConcessionari.push({
        'concessionario_codice': IppicaConst.CONCESSIONARIO_VIRTUALE,
        'concessionario_descrizione': IppicaConst.CONCESSIONARIO_VIRTUALE,
        visible: false
      })

      return {
        ...state,
        concessionari: tempConcessionari,
      };
    case IppicaActionsType.GET_CONCESSIONARI:
      return state
    case IppicaActionsType.CHANGE_VISUALIZATION_CONCESSIONARIO:
      tempConcessionari = [...state.concessionari]
      index = tempConcessionari.indexOf(action.payload)
      tempConcessionari[index].visible = !tempConcessionari[index].visible
      concessionari_da_non_contare = getConcessionariNonVisualizzati(state.concessionari)
      tempQuote = {...state.quote}
      Object.keys(tempQuote).map((evento_id)=>{
        Object.keys(tempQuote[evento_id]).map((quota_id)=>{
          tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['value'] = getMaxOdds(tempQuote[evento_id][quota_id], concessionari_da_non_contare)
        })
      })
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0);
      stato = {
        ...state,
        concessionari: tempConcessionari,
        quote: tempQuote,
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      return {
        ...stato,
        eventi: tempEventi
      }
    case IppicaActionsType.ADD_EVENTI:
      eventi_scaricati = []
      tempEvento = {}
      var loading_state = state.loading
      if(action.payload.length > 0){
        action.payload.forEach(evento =>{
          tempEvento = inizializzaEventoCampi(evento, state.specialita)
          tempEvento = filterCheckEvento(state, tempEvento)
          eventi_scaricati.push(tempEvento)
        })
        loading_state = false
      }else{
        loading_state = false
      }
      return {
        ...state,
        eventi: eventi_scaricati,
        loading: loading_state
      }
    case IppicaActionsType.ADD_QUOTE:
      return {
        ...state,
        quote: action.payload
      }
    case IppicaActionsType.GET_QUOTE_EVENTO:
      return state
    case IppicaActionsType.SET_QUOTA_EVENTO:
      tempQuote = {...state.quote}
      quote = action.payload.quote
      evento_id = action.payload.evento_id
      var errore_quote = action.payload.errore_quote
      tempEventi = [...state.eventi]
      index = tempEventi.findIndex((evento) => {
        return evento.evento_id === evento_id
      })
      if(index != -1){
        tempEventi[index].errore_quote = errore_quote
      }
      if(!errore_quote){
        if(quote && Object.keys(quote).length > 0){          
          if(index != -1){
            // ordino l'insieme dei marcatori in base al valore delle quote in maniera crescente
            tempEventi[index].marcatori = ordinaCompetitorPerFavorito(tempEventi[index], quote)
          }
        }
        
        //evento_id = 0 
        concessionari_da_non_considerare = getConcessionariNonVisualizzati(state.concessionari)
        if(quote && Object.keys(quote).length > 0)
        {
          Object.keys(quote).map((key)=>{
            evento_id = key
            Object.keys(quote[key]).map((quota_id) =>{
              quote[key][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE] = {} 
              quote[key][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['value'] = getMaxOdds(quote[key][quota_id])
            })
            tempQuote[key] = checkEventoAlert(key, quote, state.distance_banca, state.distance_punta, concessionari_da_non_considerare)
          }
          )
        }
      }
      stato = {
        ...state,
        quote: tempQuote,
      }
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      });
      
      return {
        ...state,
        quote: tempQuote,
        eventi: tempEventi,
        ultimo_aggiornamento: Date.now()
      }

    case IppicaActionsType.ADD_EVENTO:
    case IppicaActionsType.SET_QUOTA:
      var temp_state_evento = {...state}
      var campionati_aggiornati
      tempCampionati = [...state.campionati] // inserito i campionati poichè è possibile che si debba rigenerare l'elenco
      if(action.type == IppicaActionsType.ADD_EVENTO) {
        evento = action.payload.evento
        eventi_scaricati = [...state.eventi]
        tempEvento = {}
        if(evento.length > 0){
          evento.forEach(ev => {
            // ricerco se l'evento è già presente nella lista degli eventi
            var evento_presente = eventi_scaricati.find((evento_temp)=> {
              return evento_temp.evento_id == ev.evento_id
            })
            // nel caso fosse già presente non lo aggiungo alla lista degli eventi
            if (evento_presente === undefined) {
              tempEvento = inizializzaEventoCampi(ev, state.specialita)
              tempEvento.marcatori.forEach(marc => {
                marc.visible = !state.alert_eye
              })
              tempEvento = filterCheckEvento(state, tempEvento)
              eventi_scaricati.push(tempEvento)
            }
          })
          // genero la lista dei campionati presenti ed rigenero la lista dei campionati nel caso in cui
          // i nuovi eventi appartegono a nuovi campionati
          var campionatiInseriti = []
          state.campionati.forEach((campionato)=>{
            campionatiInseriti.push(campionato.campionato_id)
          })
          campionati_aggiornati = campionato_generaelenco(eventi_scaricati, state.filtro_data, state.specialita, state.campionati, campionatiInseriti, state.visualizza_quote_presenti);

          temp_state_evento = {
            ...state,
            eventi: eventi_scaricati,
            campionati: campionati_aggiornati
          }
        }
      }
      tempQuote = {...temp_state_evento.quote}
      evento_id = action.payload.evento_id
      quota_id = action.payload.quota_id
      concessionario_codice = action.payload.concessionario_codice  
      concessionari_da_non_contare = getConcessionariNonVisualizzati(temp_state_evento.concessionari)
      if(!(evento_id in tempQuote)){
        tempQuote[evento_id] = {}
      }
      if(!(quota_id in tempQuote[evento_id])){
        tempQuote[evento_id][quota_id] = {}
      }
      // stato = {
      //   ...temp_state_evento,
      //   quote: tempQuote,
      // }
      tempEventi = [...temp_state_evento.eventi]
      let runRecomputeRowHeights = true;
      if((evento_id in tempQuote) && (quota_id in tempQuote[evento_id])){ 
        if(!(concessionario_codice in tempQuote[evento_id][quota_id])){
            tempQuote[evento_id][quota_id][concessionario_codice] = {}
        }
        if(!(IppicaConst.CONCESSIONARIO_VIRTUALE in tempQuote[evento_id][quota_id])){
          tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE] = {}
        }
        // imposto la quota valore 
        tempQuote[evento_id][quota_id][concessionario_codice]['value'] = action.payload.valore
        // imposto la quota valore 2
        tempQuote[evento_id][quota_id][concessionario_codice]['value_2'] = action.payload.valore_2
        if(!('value' in tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE])){
          tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['value'] = 0
        }
        if(!IppicaConst.CONFRONTO.includes(concessionario_codice) && action.payload.valore > tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['value']){
          tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['value'] = action.payload.valore
        }else{
          tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['value'] = getMaxOdds(tempQuote[evento_id][quota_id], getConcessionariNonVisualizzati(state.concessionari))
        }
        // 0 indicher� nessun alert, 1 indicher� solo BF, 2 indicher� un alert rispetto alla quota media
        if(!('alert' in tempQuote[evento_id][quota_id][concessionario_codice])){
          tempQuote[evento_id][quota_id][concessionario_codice]['alert'] = 0 
        }
        if(!('alert' in tempQuote[evento_id][quota_id][concessionario_codice])){
          tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['alert'] = 0 
        }
        var souna_alert = false
        if(!IppicaConst.CONFRONTO.includes(concessionario_codice)) {
          old_alert = tempQuote[evento_id][quota_id][concessionario_codice]['alert']
          tempQuote[evento_id][quota_id][concessionario_codice]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_codice, tempQuote, state.distance_banca, state.distance_punta) 
          tempQuote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['alert'] = checkQuotaAlertBF(evento_id, quota_id, IppicaConst.CONCESSIONARIO_VIRTUALE, tempQuote, state.distance_banca, state.distance_punta) 
          
          // controllo se l'alert che è scattato è un alert abilitato
          if(old_alert !== tempQuote[evento_id][quota_id][concessionario_codice]['alert'] && state.alert_sound_enabled){
            souna_alert = valuateAlerts(old_alert, tempQuote[evento_id][quota_id][concessionario_codice]['alert'], state.alert_attivi)
          }
          //souna_alert = old_alert < tempQuote[evento_id][quota_id][concessionario_codice]['alert'] && state.alert_sound_enabled 
          
        } else {
          Object.keys(tempQuote[evento_id][quota_id]).map(concessionario_code => {
            old_alert =tempQuote[evento_id][quota_id][concessionario_code]['alert'] 
            tempQuote[evento_id][quota_id][concessionario_code]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_code, tempQuote, state.distance_banca, state.distance_punta) 
            souna_alert = souna_alert || valuateAlerts(old_alert, tempQuote[evento_id][quota_id][concessionario_code]['alert'], state.alert_attivi)
          })
        }
        stato = {
          ...temp_state_evento,
          quote: tempQuote,
        }

        // cerco evento tramite evento_id in tempEventi
        var evento_temp = tempEventi.find(function(evento) {
          return evento.evento_id  === evento_id
        })
        if (evento_temp != undefined) {
          // cerco la specialita_id associata alla quota id in evento_temp.marcatori
          var specialita_id = undefined
          evento_temp.marcatori.map(marcatore => {
            if(specialita_id == undefined){
              specialita_id =  Object.keys(marcatore.specialita).find(function(key) {
                return parseInt(marcatore.specialita[key])  === quota_id
              })
            }
            
          })
          if (specialita_id != undefined) {
            if(!evento_temp.specialita_attive[specialita_id]){
              souna_alert = false
            }
          }
        }
        // tempEventi.forEach(evento => {
        //   evento = filterCheckEvento(stato, evento)
        // })
         // controllare se l'evento è visibile e il competitor è visibile
        var index_evento = stato.eventi.findIndex(function(evento) {
          return evento.evento_id  === evento_id
        });
        var evento_cancellato = false
        runRecomputeRowHeights = (index_evento != -1);
        if(index_evento != -1){
          if (concessionario_codice != IppicaConst.CONCESSIONARIO_BETFAIR && !(action.payload.valore > 1 && tempEventi[index_evento].quote_presenti)) {
            // evito di rideterminare se l'evento ha quote ed eventualmente rifedinire i campionati,
            // quando mi arriva una quota valida e l'evento contiene già quote valide
            if(action.payload.valore > 1 && !tempEventi[index_evento].quote_presenti){
              // quando ricevo una quota valida su un evento senza quote, definisco l'evento con quote e rigenero i campionati
              tempEventi[index_evento].quote_presenti = true;
              // controllo se il campionato è già visibile o meno
              let campionato_presente = state.campionati.find(function(campionato) {
                return campionato.campionato_id  === tempEventi[index_evento].campionato_id
              })
              // se il campionato non è presente allora rigenero l'elenco dei campionati, salvando sempre lo stato precedente 
              if(campionato_presente === undefined){
                tempCampionati = campionato_generaelenco(tempEventi, state.filtro_data, state.specialita, [], [], state.campionati, state.visualizza_quote_presenti)
              }
            } else if (action.payload.valore <= 1 && tempEventi[index_evento].quote_presenti){
              // quando ricevo una quota non valida su un evento con quote, controllo se l'evento ha ancora quote valide
              tempEventi[index_evento].quote_presenti = verificaQuotePresentiEvento(tempQuote[evento_id])
              if(!tempEventi[index_evento].quote_presenti){
                // se l'evento non ha più quote valide, verifico se eliminarlo poichè è iniziato
                if(tempEventi[index_evento].timestamp < ((new Date()).getTime() / 1000) - (new Date().getTimezoneOffset() * 60)){
                  tempEventi.splice(index_evento, 1)
                  evento_cancellato = true
                }
                // se l'evento non ha più quote valide allora rigenero l'elenco dei campionati
                tempCampionati = campionato_generaelenco(tempEventi, state.filtro_data, state.specialita, [], [], state.campionati, state.visualizza_quote_presenti)
              }
            }
            // ridefinisco lo stato da fornire alla funzione filterCheckEvento in modo tale che comprenda anche i filtri per campionato
            stato = {
              ...stato,
              campionati: tempCampionati,
            }
          } else {
            // se non aggiungo o rimuovo eventi, inutile ricalcolare la visualizzazione degli eventi
            runRecomputeRowHeights = false;
          }
          // se l'evento non è stato rimosso precedentemente allora procedo con il filtraggio
          if(!evento_cancellato){
            // TODO: controllare se il visibile dell'evento o dell competitor è cambiato per impostare l'esecuzione del recompute
            tempEventi[index_evento] = filterCheckEvento(stato, tempEventi[index_evento], quota_id)
            if(souna_alert){
              if(tempEventi[index_evento].enabled && tempEventi[index_evento].visible){
                index_competitor = tempEventi[index_evento].marcatori.findIndex(function(competitor) {
                  return Object.keys(competitor.specialita).find(key => parseInt(competitor.specialita[key]) === parseInt(quota_id))
                });
                if(index_competitor != -1){
                  if(tempEventi[index_evento].marcatori[index_competitor].enabled){
                    suono_alert.play()
                  }
                }
              }
            }
          }
        }
      }

      //if(state.ref_list !== null && runRecomputeRowHeights) {
      if(state.ref_list !== null) {
        state.ref_list.recomputeRowHeights(0)
      }
        
      return {
        ...stato,
        eventi: tempEventi,
        ultimo_aggiornamento: Date.now(),
        campionati: tempCampionati,
      }
    case IppicaActionsType.LOAD_QUOTE:
      return state
    case IppicaActionsType.ADD_SPECIALITA:
      tempSpecialita = []
      action.payload.forEach((spec)=>{
        tempSpecialita.push({...spec, visible:true})
      })
      // ordino tempSpecialita per descrizione e la descrizione 'Vincente' la metto per prima
      tempSpecialita.sort((a,b) => {
        if(a.descrizione === 'Vincente'){
          return -1
        }
        if(b.descrizione === 'Vincente'){
          return 1
        }
        if(a.descrizione < b.descrizione){
          return -1
        }
        if(a.descrizione > b.descrizione){
          return 1
        }
        return 0
      })
      return {
        ...state,
        specialita: tempSpecialita
      }
    case IppicaActionsType.CHANGE_VISUALIZATION_SPECIALITA:
      tempSpecialita = [...state.specialita]
      index = tempSpecialita.indexOf(action.payload)
      tempSpecialita[index].visible = !tempSpecialita[index].visible
      return {
        ...state,
        specialita: tempSpecialita
      }
    case IppicaActionsType.GET_SPECIALITA:
      return state
    case IppicaActionsType.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      }
    case IppicaActionsType.SET_CAMPIONATI:
      tempCampionati = campionato_generaelenco(action.payload, state.filtro_data, state.specialita, [], [], [], state.visualizza_quote_presenti);
      tempEventi = [...state.eventi]
      tempCampionati.forEach(campionato => {
        // verifico se una sottostringa è presente nel nome del campionato
        Object.keys(IppicaConst.SPECIALITA_DISATTIVATE_CAMPI).map(key => {
          if(campionato['campionato_descrizione'].toUpperCase().startsWith(key)){
            // chiamo funzione che permette di cambiare le specialita attive agli eventi del campionato
            setSpecialitaAttivePerCampionato(tempEventi, campionato.campionato_id, IppicaConst.SPECIALITA_DISATTIVATE_CAMPI[key])
            Object.keys(IppicaConst.SPECIALITA_DISATTIVATE_CAMPI[key]).map(specialita_id => {
              campionato.specialita_attive[specialita_id] = false
            })
          }
        })
      })
      return {
        ...state,
        campionati: tempCampionati,
        eventi: tempEventi,
      }
    case IppicaActionsType.CHANGE_VISUALIZATION_ALL_EVENTI:
      tempEventi = state.eventi
      tempCampionati = [...state.campionati]
      tempCampionati.forEach(campionato => {
        campionato.visible = action.payload  
      })
      var stato = {
        ...state,
        campionati: tempCampionati,
      }
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0);
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento) 
      });
      return {
        ...stato,
        eventi: tempEventi,
      }
    case IppicaActionsType.CHANGE_VISUALIZATION_EVENTI:
      tempEventi = state.eventi
      tempCampionati = [...state.campionati]
      index = tempCampionati.indexOf(action.payload)
      tempCampionati[index].visible = !tempCampionati[index].visible
      var stato = {
        ...state,
        campionati: tempCampionati,
      }
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0);
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento) 
      });
      return {
        ...stato,
        eventi: tempEventi,
      }
    case IppicaActionsType.SET_DISTANCE:
      var stato = {
        ...state,
        distance_banca: action.payload
      }
      //saveLocalState(stato)
      return stato
    case IppicaActionsType.CHECK_ALERT_DISTANCE:
      tempQuote = {...state.quote}
      Object.keys(tempQuote).map((evento_id)=>{
        Object.keys(tempQuote[evento_id]).map((quota_id) => {
          // prendo quello a posizione 0 che suppongo sia BETFAIR dato che il controllo da effettuare è su BF
          if(IppicaConst.CONCESSIONARIO_BETFAIR in tempQuote[evento_id][quota_id]){    
            Object.keys(tempQuote[evento_id][quota_id]).map( (concessionario_codice) => {
                if (!IppicaConst.CONFRONTO.includes(concessionario_codice)){
                  tempQuote[evento_id][quota_id][concessionario_codice]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_codice, tempQuote, state.distance_banca, state.distance_punta)      
                }    
            })
          }
        })
          
      })
      var stato = {
        ...state,
        quote: tempQuote
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if(state.alert_eye && state.ref_list !== null){
        state.ref_list.recomputeRowHeights(0);
      }
      return {
          ...stato,
          eventi: tempEventi
      }
    case IppicaActionsType.SET_EYE_STATE:
      tempEventi = [...state.eventi]
      tempQuote = {...state.quote}
      //index = null
      eventi_da_non_visualizzare = []
      var stato = {
        ...state,
        alert_eye: action.payload,
        filtro_competitor: '',
      }
      alert_abilitati = action.payload.alert_abilitati
      tempEventi.forEach(evento =>{
        evento = filterCheckEvento(stato, evento)
      })
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0);
      return {
        ...stato,
        eventi: tempEventi,
      }
    case IppicaActionsType.CHECK_EVENT_ALERT_AVG_PERCENTUALE:
      tempQuote = {...state.quote}
      evento_id = action.payload
      concessionari_da_non_considerare = getConcessionariNonVisualizzati(state.concessionari)
      Object.keys(tempQuote[evento_id]).map((quota_id)=>{
        competitor = tempQuote[evento_id][quota_id]
       })
      return {
        ...state,
        quote: tempQuote
      }
    case IppicaActionsType.CHANGE_STATE_ALERT_SOUND:
      return {
        ...state,
        alert_sound_enabled: !state.alert_sound_enabled
      }
      case IppicaActionsType.DELETE_STARTED_EVENT:
        tempEventi = []
        tempQuote = {...state.quote}
        currentTimestamp = (new Date().getTime())/1000
        evento_rimosso = false;
        state.eventi.forEach((evento) => {
          if (evento.timestamp > currentTimestamp){
            // aggiungo nel caso l'evento non è ancora iniziato
            tempEventi.push(evento)
          } else{
            // nel caso l'evento fosse già iniziato pulisco anche la struttura delle quote
            tempQuote = cleanQuote(evento.evento_id, tempQuote)
            evento_rimosso = true;
          }
        })
        if (evento_rimosso) {
          return {
            ...state,
            eventi: tempEventi,
            quote: tempQuote,
          }
        } else {
          return state;
        }
    case IppicaActionsType.SET_ENABLED_STATE_EVENT:
      tempEvento = action.payload.evento
      tempEventi = [...state.eventi]
      index = tempEventi.indexOf(tempEvento)
      tempEventi[index].enabled = action.payload.enabled
      if(state.ref_list !== null)  
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi      
      }
    case IppicaActionsType.SET_ENABLED_STATE_COMPETITOR:
        tempEvento = action.payload.evento
        tempEventi = [...state.eventi]
        index = tempEventi.indexOf(tempEvento)
        index_competitor = tempEventi[index].marcatori.indexOf(action.payload.competitor)
        tempEventi[index].marcatori[index_competitor].enabled = action.payload.enabled
        stato = {
          ...state,
          eventi: tempEventi
        }
        if (state.alert_eye){
          tempEventi[index] = filterCheckEvento(stato, tempEventi[index])
        }
        if(state.ref_list !== null)
          state.ref_list.recomputeRowHeights(0)
        return {
          ...stato,
          eventi: tempEventi
        } 
    // case IppicaActionsType.SET_EYE_STATE:
    //   var ref = state.ref_list.recomputeRowHeights(0)
    //   state = {
    //     ...state,
    //     alert_eye: action.payload,
    //     ref_list: ref
    //   };
    //   return state; 
    case IppicaActionsType.SET_ALERT_ABILITATI:
      stato = {
        ...state,
        alert_attivi: action.payload
      };
      tempEventi = [...stato.eventi]
      tempEventi.forEach(evento =>{
        evento = filterCheckEvento(stato, evento)
      })
      if(stato.ref_list !== null)
      {
        stato.ref_list.recomputeRowHeights(0);
      }
      return {
        ...stato,
        eventi: tempEventi
      };
    case IppicaActionsType.SET_FILTRO_TIPO_DATA:
      // per fare in modo di filtrare bene gli eventi l'elenco dei campionati deve essere generato prima
      tempEventi = [...state.eventi]
      // ridefinisco l'elenco dei campionati rispettando l'intervallo di data degli eventi
      // e cioè verranno visualizzati solo i campionati che contegono eventi nell'intervallo di data selezionato
      // genero la lista dei campionati presenti ed rigenero la lista dei campionati nel caso in cui
      tempCampionati = campionato_generaelenco(tempEventi, action.payload, state.specialita, [], [], state.campionati, state.visualizza_quote_presenti); 
    
      var stato = {
        ...state,
        campionati: tempCampionati,
        filtro_data: action.payload
      }
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0);
        
      return {
        ...stato,
        eventi: tempEventi,
      }
    case IppicaActionsType.SET_REF_LIST:
      return{
        ...state,
        ref_list: action.payload
      }
    case IppicaActionsType.SET_OPEN_STATE_COMPETITOR:
      tempEventi = [...state.eventi]
      index_evento = tempEventi.findIndex((evento) => evento === action.payload.evento)
      index_competitor = tempEventi[index_evento].marcatori.findIndex((competitor) => competitor === action.payload.competitor)
      tempEventi[index_evento].marcatori[index_competitor].open = action.payload.state
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi,
      }
    case IppicaActionsType.UPDATE_LIST:
      var ref = state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        ref_list: ref}
    case IppicaActionsType.ALL_SET_OPEN_STATE_COMPETITOR:
      tempEventi = [...state.eventi]
      var state_open = action.payload
      tempEventi.forEach(evento => {
        evento.marcatori.forEach(competitor =>{
          competitor.open = state_open;
        })
      })
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi
      }
    case IppicaActionsType.SET_OPEN_STATE_EVENTO:
      tempEventi = [...state.eventi];
      index_evento = tempEventi.findIndex((evento) => evento.evento_id === action.payload.evento.evento_id);
      tempEventi[index_evento].open = action.payload.state;
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi,
        ref_list: ref,
      }
    case IppicaActionsType.ALL_SET_OPEN_STATE_EVENTO:
      tempEventi = [...state.eventi];
      tempEventi.forEach(evento => {
        evento.open = action.payload
      })
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi,
      }
    case IppicaActionsType.SAVE_IMPOSTAZIONI:
      saveLocalState(state)
    case IppicaActionsType.SET_FILTRO_EVENTO_NAME:
      var stato = {
        ...state,
        filtro_competitor: '',
        filtro_evento: action.payload
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0);
      return {
        ...stato, 
        eventi: tempEventi
      }
    case IppicaActionsType.SET_FILTRO_COMPETITOR:
      var stato = {
        ...state,
        filtro_evento: '',
        filtro_competitor: action.payload,
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0);
      return {
        ...stato, 
        eventi: tempEventi,
      }
    case IppicaActionsType.CHANGE_TIPO_ORDINAMENTO:
      var tipo_ordinamento = action.payload
      var tempEventi = [...state.eventi]
      if (tipo_ordinamento === IppicaConst.TIPO_ORDINAMENTO_PER_DATA){
        // ordinare gli eventi per data
        tempEventi.sort((a, b) => {
          if (a.timestamp < b.timestamp){
            return -1
          } else if (a.timestamp > b.timestamp){
            return 1
          } else {
            return 0
          }
        })
        
      } else if (tipo_ordinamento === IppicaConst.TIPO_ORDINAMENTO_PER_CAMPIONATO){
        // ordinare gli eventi per campionato
        tempEventi.sort((a, b) => {
          if (a.campionato_descrizione < b.campionato_descrizione){
            return -1
          } else if (a.campionato_descrizione > b.campionato_descrizione){
            return 1
          } else {
            if (a.timestamp < b.timestamp){
              return -1
            } else if (a.timestamp > b.timestamp){
              return 1
            } else {
              return 0
            }
          }

        })
      }
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0); 
      return {
        ...state,
        eventi: tempEventi,
      }
    case IppicaActionsType.SET_SPECIALITA_EVENTO:
      evento = action.payload.evento
      var specialita_id = action.payload.specialita_id
      var attiva = action.payload.attiva
      var tempEventi = [...state.eventi]
      var index_evento = tempEventi.findIndex((evento_temp) => evento_temp.evento_id === action.payload.evento.evento_id)
      if(index_evento !== -1){
        tempEventi[index_evento].specialita_attive[parseInt(specialita_id)] = attiva
        tempEventi[index_evento] = filterCheckEvento(state, tempEventi[index_evento])
        if(state.ref_list !== null)
          state.ref_list.recomputeRowHeights(0);
      }
      return {
        ...state,
        eventi: tempEventi
      }
    case IppicaActionsType.SET_SPECIALITA_CAMPIONATO:
      campionato = action.payload.campionato
      var attiva_campionato = action.payload.attiva
      var specialita_id = action.payload.specialita_id
      var tempEventi = [...state.eventi]
      tempCampionati = [...state.campionati]
      var index_campionato = tempCampionati.findIndex((campionato_temp) => campionato_temp.campionato_id === campionato.campionato_id)
      if (index_campionato !== -1){
        tempCampionati[index_campionato].specialita_attive[parseInt(specialita_id)] = attiva_campionato
        tempEventi.forEach(evento => {
          if(evento.campionato_id === campionato.campionato_id){
            evento.specialita_attive[parseInt(specialita_id)] = attiva_campionato
            evento = filterCheckEvento(state, evento)
          }
        })
        if(state.ref_list !== null)
          state.ref_list.recomputeRowHeights(0);
      }
      return {
        ...state,
        eventi: tempEventi,
        campionati: tempCampionati
      }
    case IppicaActionsType.SET_VISUALIZZA_QUOTE_PRESENTI:
      tempEventi = [...state.eventi]
      stato = {
        ...state,
        visualizza_quote_presenti: action.payload
      }
      tempCampionati = campionato_generaelenco(tempEventi, stato.filtro_data, stato.specialita, [], [], stato.campionati, action.payload)
      stato = {
        ...stato,
        campionati: tempCampionati
      }
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })

      
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0);
      return {
        ...stato,
        eventi: tempEventi,
        campionati: tempCampionati
      }
    case IppicaActionsType.SET_QUOTA_DESCRIZIONE:
      tempQuote = {...state.quote}
      evento_id = action.payload.evento_id
      quota_id = action.payload.quota_id
      concessionario_codice = action.payload.concessionario_codice
      let jsonDescrizione = action.payload.descrizione
      // controllo prima se la chiave 'quota_descrizione' è presente nel json
      // in caso contrario la chiamata può essere andara in errore
      if('quota_descrizione' in jsonDescrizione){
        if(evento_id in tempQuote){
          if(!(quota_id in tempQuote[evento_id])){
            tempQuote[evento_id][quota_id] = {}
          }
          if(!(concessionario_codice in tempQuote[evento_id][quota_id])){
            tempQuote[evento_id][quota_id][concessionario_codice] = {}
          }
          tempQuote[evento_id][quota_id][concessionario_codice]['quota_descrizione'] = jsonDescrizione['quota_descrizione']
        }
      }
      return {
        ...state,
        quote: tempQuote
      }
    case IppicaActionsType.SET_DISTANCE_PUNTA:
      return {
        ...state,
        distance_punta: action.payload
      }
    case IppicaActionsType.CHECK_ALERT_DISTANCE_PUNTA:
      tempQuote = {...state.quote}
      Object.keys(tempQuote).map((evento_id)=>{
        Object.keys(tempQuote[evento_id]).map((quota_id) => {
          if(IppicaConst.CONCESSIONARIO_BETFAIR in tempQuote[evento_id][quota_id]){    
            Object.keys(tempQuote[evento_id][quota_id]).map( (concessionario_codice) => {
                if (!IppicaConst.CONFRONTO.includes(concessionario_codice)){
                  tempQuote[evento_id][quota_id][concessionario_codice]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_codice, tempQuote, state.distance_banca, state.distance_punta)      
                }    
            })
          }
        })
          
      })
      var stato = {
        ...state,
        quote: tempQuote
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if(state.alert_eye && state.ref_list !== null){
        state.ref_list.recomputeRowHeights(0);
      }
      return {
          ...stato,
          eventi: tempEventi
      }
    default:
      return state
  } 
}
export default Ippica;
